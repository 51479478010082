/* eslint-disable */
import AOS from 'aos'

!(function($) {
  
  "use strict";

  // // Preloader
  // $(window).on('load', function() {
  //   if ($('#preloader').length) {
  //     $('#preloader').delay(100).fadeOut('slow', function() {
  //       $(this).remove();
  //     });
  //   }
  // });

  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 50) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });

  const body = document.querySelector('.back-to-top')
  if (body) {
    body.addEventListener("click", window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     }))
  }

  // Porfolio isotope and filter
  // $(window).on('load', function() {
  //   var portfolioIsotope = $('.portfolio-container').isotope({
  //     itemSelector: '.portfolio-item'
  //   });

  //   $('#portfolio-flters li').on('click', function() {
  //     $("#portfolio-flters li").removeClass('filter-active');
  //     $(this).addClass('filter-active');

  //     portfolioIsotope.isotope({
  //       filter: $(this).data('filter')
  //     });
  //     aos_init();
  //   });

  //   // Initiate venobox (lightbox feature used in portofilo)
  //   $(document).ready(function() {
  //     $('.venobox').venobox({
  //       'share': false
  //     });
  //   });
  // });

  // Portfolio details carousel
  // $(".portfolio-details-carousel").owlCarousel({
  //   autoplay: true,
  //   dots: true,
  //   loop: true,
  //   items: 1
  // });

  // Init AOS
  function aos_init() {
    AOS.init({
      duration: 1000,
      once: true
    });
  }
  $(window).on('load', function() {
    aos_init();
  });

})(jQuery);