/* eslint-disable import/no-unresolved */
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
// import dotenv from 'dotenv'
import App from './App.vue'
import router from './router'
import store from './store'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

// using AOS
import 'aos/dist/aos.css'

// dotenv.config()

// Import css style
require('@/assets/css/styles.css')
require('@/assets/scss/style.scss')
require('@/assets/scss/responsive.scss')
// require('@/assets/css/landing.css')
// require('@/assets/css/responsive.css')

// import assets styles

// import assets Script
require('@/assets/js/scripts')

Vue.use(VueMeta, {
  refreshOnceNavigation: true,
})
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
