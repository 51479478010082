<template>
  <div class="h-100">
    <router-view />
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    if (localStorage.lang !== null) this.$i18n.locale = localStorage.lang
  },
}

</script>

<style>
</style>
